export interface RouteDetails {
  destination?: string;
  domain?: string;
  fragment?: string;
  fragmentDay?: number;
  hasChatOpen?: boolean;
  hash: string;
  section?: ItinerarySection;
}

export interface ItinerarySection {
  display: string;
  segment: string;
}

export enum ItinerarySectionEnum {
  BOOKING_DETAILS = 'booking-details',
  CHAT_OPEN = 'chat',
  CHECKOUT = 'checkout',
  CONTACT = 'contact',
  EDITOR_TIPS = 'editor-tips',
  ITINERARY = '',
}

export const ITINERARY_SECTIONS: ItinerarySection[] = [
  { segment: ItinerarySectionEnum.ITINERARY, display: 'Itinerary' },
  { segment: ItinerarySectionEnum.EDITOR_TIPS, display: 'Editor Tips' },
  { segment: ItinerarySectionEnum.CONTACT, display: 'Contact' },
  { segment: ItinerarySectionEnum.CHAT_OPEN, display: 'Itinerary' },
];

export enum Domains {
  ITINERARY = 'itinerary',
  TRIP = 'trip',
}
