import { Injectable } from '@angular/core';
import { NavigationEnd, PRIMARY_OUTLET, Router, UrlTree } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Observable, OperatorFunction } from 'rxjs';
import {
  ITINERARY_SECTIONS,
  ItinerarySectionEnum,
  RouteDetails,
  Domains,
} from '@shared/models/itinerary/route-details.model';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  constructor(private router: Router) {}
  public getRouteDetails$(): Observable<RouteDetails> {
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map(navigationEnd => this.router.parseUrl(navigationEnd.urlAfterRedirects)) as OperatorFunction<
        NavigationEnd,
        UrlTree
      >,
      this.mapRouteDetails$,
    );
  }

  private mapRouteDetails$(urlTree$: Observable<UrlTree>): Observable<RouteDetails> {
    return urlTree$.pipe(
      map(urlTree => {
        //  url = domain/segment1/segment2#fragment --> segments = ['segment1', 'segment2'] fragment = 'fragment'
        const segments = urlTree.root.children[PRIMARY_OUTLET].segments.map(urlSegment => urlSegment.path);
        const [domain, hash, sectionSegment = '', destination] = segments;
        const section = ITINERARY_SECTIONS.find(itinerarySection => itinerarySection.segment === sectionSegment);
        const fragment = urlTree.fragment ?? undefined;
        const fragmentDay = fragment && !isNaN(+fragment) ? +fragment : undefined;

        if (
          (domain == Domains.ITINERARY || domain == Domains.TRIP) &&
          section?.segment === ItinerarySectionEnum.ITINERARY
        ) {
          return { domain, hash, section, destination, fragment, fragmentDay };
        } else {
          return {
            domain,
            hash,
            section,
            destination,
            hasChatOpen: section?.segment === ItinerarySectionEnum.CHAT_OPEN,
          };
        }
      }),
    );
  }
}
